// extracted by mini-css-extract-plugin
export var bold = "lh";
export var info__item = "eh";
export var info__list = "ch";
export var info__work = "dh";
export var item__icon = "jh";
export var item__label = "kh";
export var label = "ah";
export var main = "Zg";
export var main__info = "bh";
export var main__primary = "_g";
export var price = "gh";
export var salary = "hh";
export var schedule = "fh";
export var type = "ih";